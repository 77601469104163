<template>
  <div>
    <PageTitle title="Map" />

    <hr>

    <b-container fluid="xxl">
      <div>
        <b-row>
          <b-col>
            <LocationSelect
              ref="selectComponent"
              @select="selectLocation"
              settings="{ inMap: true }"
            />
          </b-col>
          <b-col>
            <b-button
              v-if="config.editable"
              id="saveButton"
              variant="primary"
              :disabled="!savable"
              @click="saveLocation"
              size="sm">
              Save Geo-Location
            </b-button>

            <b-button
              id="showAllButton"
              variant="primary"
              @click="showAll"
              size="sm">
              Toggle Show All
            </b-button>

          </b-col>
          <!-- <b-col>
            <span>current Position: {{ curPos }}</span>
          </b-col> -->
        </b-row>
      </div>

      <hr>

      <div id="mapContainer">
        <l-map
          ref="Map"
          :zoom="zoom"
          :minZoom="5"
          :center="center"
          style="height: 80%"
          :maxBounds="maxBounds"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
          @click="addMarker"
        >
          <l-tile-layer
            id="map"
            :url="url"
            :attribution="attribution"
          />

          <l-marker
            v-for="curLoc in currentGeoLocation"
            :key="curLoc[0]"
            :lat-lng="curLoc"
            :draggable="true"
            :icon="myMarkerIcon"
            @dragend="getMarkerPosition">
          </l-marker>

          <l-marker
            v-for="loc in allGeoLocations"
            :key="loc[0]"
            :lat-lng="[loc.latitude, loc.longitude]"
            :icon="myMarkerIcon"
            >
            <l-popup :content="loc.display"></l-popup>
          </l-marker>

        </l-map>
      </div>
    </b-container>
  </div>

</template>

<style scoped>
  .leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
    }
  #mapContainer {
    width: 90%;
    height: 1020px;
    border-radius: 7px;
    margin: auto;
  }
  #saveButton{
    margin-right:10px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import PageTitle from '@/components/PageTitle.vue'
  import LocationSelect from '@/components/Main/LocationSelect.vue'
  import "leaflet/dist/leaflet.css";
  import { latLng } from "leaflet";
  import L from "leaflet";
  import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
  import config from '@/config.js'
  import $ from "jquery";
  // import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

  export default {
    name: 'LocationsList',
    data(){
      const myMarkerIcon = L.icon({
         iconUrl: require('leaflet/dist/images/marker-icon.png'),
         shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
         iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
         iconSize: [25, 41],
         iconAnchor: [12, 41]
      });
      return {
        zoom: 7,
        allZoom: 6,
        maxZoom: 10,
        center: latLng(46.98766, 3.15772),
        maxBounds: [
            [62.01447753807476, -24.4854345428153435],
            [30.3782598746379, 29.98511485959183],
          ],
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        // withPopup: latLng(48.856614, 2.352222),
        // withTooltip: latLng(47.484227, 4.241990),
        currentGeoLocation: [],
        allGeoLocations: [],
        currentZoom: 11.5,
        currentCenter: latLng(46.98766, 3.15772),
        myMarkerIcon: myMarkerIcon,
        // curPos: null,
        savable: false,
        selectedLocation: false,
        config: config
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
    },
    components: {
      PageTitle,
      LMap,
      LTileLayer,
      LMarker,
      LocationSelect,
      LPopup
      //LTooltip
    },
    methods: {
      pruneFloat(number) {
      if (number) {
        let numStr = number.toString();
        const decPoint = numStr.indexOf(".");
        return numStr.split("").splice(0, decPoint+4).join("")
      }
        return number;
      },
      zoomUpdate(zoom) {
        this.currentZoom = zoom;
      },
      centerUpdate(center) {
        this.currentCenter = center;
      },
      addMarker(event) {
        if (this.selectedLocation) {
          //this.curPos = `${this.pruneFloat(event.latlng.lat)}, ${this.pruneFloat(event.latlng.lng)}`;
          this.currentGeoLocation = [event.latlng];
          this.allGeoLocations = [];
          this.savable = true;
        }
      },
      getMarkerPosition(marker) {
        // this.curPos = `${this.pruneFloat(marker.target._latlng.lat)}, ${this.pruneFloat(marker.target._latlng.lng)}`;
        this.currentGeoLocation = [marker.target._latlng];
        this.savable = true;
      },
      centerMap(newCenter) {
        if (newCenter) {
          this.$refs.Map.mapObject.setView(newCenter, this.zoom);
        } else {
          this.$refs.Map.mapObject.setView(this.center, this.zoom);
        }
      },
      selectLocation(location) {
        if (location.latitude && location.longitude) {
          const newLoc = latLng(location.latitude, location.longitude)
          this.currentGeoLocation = [newLoc];
          // this.curPos = `${this.pruneFloat(location.latitude)}, ${this.pruneFloat(location.longitude)}`
          this.centerMap(newLoc);
        } else {
          this.currentGeoLocation = [];
          // this.curPos = null;
          this.centerMap(null);
        }
        this.savable = false;
        this.selectedLocation = location;
        this.allGeoLocations = [];
      },
      saveLocation() {
        this.$bvModal.msgBoxConfirm('Save Geo-Location for selected Location?').then(value => {
          if (value) {
            this.selectedLocation.latitude  = this.currentGeoLocation[0].lat;
            this.selectedLocation.longitude = this.currentGeoLocation[0].lng;
            this.$store.dispatch('saveLocation', this.selectedLocation).then(() => {
              this.$store.dispatch('addNotification', {
                type: 'success',
                text: `Geo-Location saved`
                })
              })
          }})
        this.savable = false;
      },
      async showAll() {
        if (this.allGeoLocations.length == 0) {
          await this.$store.dispatch('allGeoLocations')
          this.allGeoLocations = this.$store._vm._data.$$state.location.allGeoLocations;
          this.$refs.Map.mapObject.setView(this.center, this.allZoom);
          this.currentGeoLocation = [];
          this.selectedLocation = null;
          this.savable = false;
          $("select").val("").change(); // deselects select2 component
        } else {
          this.allGeoLocations = [];
        }
      }
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
